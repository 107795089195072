import styled from '@emotion/styled';
import copy from 'fast-copy';
import { deepEqual } from 'fast-equals';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useUpdateEffect } from 'react-use';
import tw from 'twin.macro';

import {
  Button,
  Card,
  Container as InputContainer,
  Error,
  FadeIn,
  FormattedPhoneInput,
  Input,
  Loader,
  Modal,
  Portal,
  ProfilePicture as _ProfilePicture,
  UnlockMembership
} from '../../components';
import { Notification } from '../../components/Notification';
import verifyPhoneWithTwilio from '../../hooks/usePhoneNumberValidation';
import { SubscriptionType, User } from '../../models';
import { useUser } from '../../providers';

const Container = styled(FadeIn)`
  ${tw`space-y-10`}

  @media (min-width: 768px) {
    max-width: 39.5rem;
  }
`;

const Header = styled.div`
  ${tw`text-xl font-semibold`}
`;

const Content = styled.div`
  ${tw`flex flex-col mt-4 space-y-8`}
`;

/* const DangerZone = styled.div` */
/*   ${tw`flex flex-col items-center px-20 -mx-10 text-center md:text-left md:px-9 py-9 md:flex-row space-y-6 bg-primary-100 md:space-x-6 md:space-y-0 md:mx-0`} */

/*   @media (min-width: 768px) { */
/*     max-width: 30rem; */
/*   } */
/* `; */

const NameTag = styled.div`
  ${tw`flex flex-col items-center md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-4`}
`;

const ProfilePicture = styled(_ProfilePicture)`
  ${tw`text-4xl w-28 h-28 md:w-20 md:h-20`}
`;

function getMembershipLabel(subscriptionType: SubscriptionType) {
  switch (subscriptionType) {
    case SubscriptionType.Month:
      return 'Seasonal Member';
    case SubscriptionType.Year:
      return 'Annual Member';
    case SubscriptionType.Lifetime:
      return 'Lifetime Member';
  }
}

export function Account() {
  const userProvider = useUser();
  const { user: current, update, checkout } = userProvider;
  const initialPhoneNumber = current?.phoneNumber
    ? parsePhoneNumber(current.phoneNumber, 'US')?.number
    : undefined;
  // separate phone number state to be used by the react-phone-number-input
  const [phoneNumHolder, setPhoneNumHolder] = useState(initialPhoneNumber);
  const [invalidNumberError, setInvalidNumberError] = useState(false);
  const [user, setUser] = useState(copy(current));
  const [retypedPassword, setRetypedPassword] = useState('');
  const [showSaveNotification, setShowSaveNotification] = useState(false);

  const {
    register,
    formState: { isValid, errors },
    watch,
    setError
  } = useForm<User>({
    defaultValues: current
      ? {
          firstName: current.firstName,
          lastName: current.lastName,
          email: current.email,
          phoneNumber: current.phoneNumber
        }
      : { firstName: '', lastName: '', email: '', phoneNumber: '' },
    mode: 'all'
  });

  /* const [showConfirmDeleteAccount, hideConfirmDeleteAccount] = useModal( */
  /*   () => <Modal onClose={hideConfirmDeleteAccount}>Help</Modal>, */
  /*   [] */
  /* ); */

  const [openUnlockMembership, closeUnlockMembership] = useModal(
    () => (
      <Modal onClose={closeUnlockMembership} fullHeight>
        <UnlockMembership
          displaySingleOption={false}
          showShareLinkHintText={false}
          userProvider={userProvider}
          onSubmit={checkout}
        />
      </Modal>
    ),
    [user, userProvider]
  );

  useUpdateEffect(() => setUser(copy(current)), [current]);
  const email = watch('email');

  const submitUser = async () => {
    if (isValid && user) {
      if (user.phoneNumber) {
        try {
          await verifyPhoneWithTwilio(user.phoneNumber);
          setInvalidNumberError(false);
        } catch (e) {
          setInvalidNumberError(true);
          return;
        }
      }

      try {
        await update(user);
        setShowSaveNotification(true);
        setTimeout(() => {
          setShowSaveNotification(false);
        }, 3000);
      } catch (e) {
        //@ts-ignore
        const result = await e.json();
        const errorMessage = result.error.toLowerCase();
        if (errorMessage.includes('number')) {
          setError('phoneNumber', { message: result.error });
        }
        if (errorMessage.includes('email')) {
          setError('email', { message: result.error });
        }
      }
    }
  };

  if (!user) {
    return (
      <Loader>
        <div>Fetching your account...</div>
      </Loader>
    );
  }

  const canSave =
    !deepEqual(current, user) &&
    (user.phoneNumber || user.email) &&
    user.firstName &&
    (user.password || '') === retypedPassword;

  return (
    <Container>
      <Portal container="notifications">
        {showSaveNotification && <Notification>Saved changes!</Notification>}
      </Portal>
      <div className="flex flex-col justify-between flex-1 md:flex-row">
        <NameTag>
          <ProfilePicture user={user} editable />
          <div className="font-semibold text-center space-y-4 md:space-y-1 md:text-left">
            <div className="text-2xl">
              {current?.firstName} {current?.lastName}
            </div>
            <div className="inline-flex px-2 py-1 border cursor-pointer rounded-2xl border-neutral-ash">
              {user.subscriptionType ? (
                <div>{getMembershipLabel(user.subscriptionType as SubscriptionType)}</div>
              ) : (
                <div onClick={openUnlockMembership}>Choose membership</div>
              )}
            </div>
          </div>
        </NameTag>
        <Button
          inline
          disabled={!canSave}
          onClick={async () => {
            submitUser();
          }}
          className="self-end hidden md:block"
        >
          Save Changes
        </Button>
      </div>
      <form
        className="flex flex-col w-full md:items-baseline md:flex-row space-x-0 space-y-6 md:space-x-6 md:space-y-0"
        onSubmit={(e) => {
          e.preventDefault();
          submitUser();
        }}
      >
        <Card className="flex-auto flex-shrink-0">
          <Header>Profile</Header>
          <Content>
            <Input
              value={user.firstName}
              onChange={(e) => setUser({ ...user, firstName: e.target.value })}
              placeholder="First name"
            />
            <Input
              value={user.lastName}
              onChange={(e) => setUser({ ...user, lastName: e.target.value })}
              placeholder="Last name"
            />
          </Content>
        </Card>

        <Card className="flex-auto">
          <Header>Sign In</Header>
          <Content>
            <InputContainer>
              <Input
                {...register('email', {
                  required:
                    !phoneNumHolder && !email
                      ? 'Email required if no phone number given.'
                      : undefined,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Must be a valid email address.'
                  }
                })}
                placeholder="Email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
              {errors.email && <Error relativePosition>{errors.email.message}</Error>}
            </InputContainer>
            <InputContainer>
              <FormattedPhoneInput
                invalid={errors.phoneNumber || invalidNumberError}
                {...register('phoneNumber', {
                  validate: () => {
                    return phoneNumHolder
                      ? isPossiblePhoneNumber(phoneNumHolder)
                        ? undefined
                        : 'Must be a valid phone number.'
                      : undefined;
                  }
                })}
                value={phoneNumHolder}
                placeholder="Phone"
                country="US"
                onChange={(e) => {
                  setPhoneNumHolder(e);
                  setUser({ ...user, phoneNumber: e });
                }}
              />
              <div>Phone</div>
              {errors.phoneNumber && <Error relativePosition>{errors.phoneNumber.message}</Error>}
              {!errors.phoneNumber && invalidNumberError && (
                <Error relativePosition>This is not a registered mobile phone number.</Error>
              )}
            </InputContainer>
            <Input
              placeholder="New password"
              type="password"
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <Input
              placeholder="Confirm password"
              type="password"
              onChange={(e) => setRetypedPassword(e.target.value)}
            />
          </Content>
        </Card>

        <div className="flex flex-col items-center pt-8 pb-10 md:hidden space-y-10">
          <Button disabled={!canSave} type="submit">
            Save Changes
          </Button>
          <div
            className="cursor-pointer select-none text-interaction"
            onClick={() => setUser(copy(current))}
          >
            Clear Changes
          </div>
        </div>
      </form>

      {/* <DangerZone> */}
      {/*   <div className="space-y-2"> */}
      {/*     <Header className="text-center md:text-left">Danger Zone!</Header> */}
      {/*     <p> */}
      {/*       If you’d like to change your membership status, please go to your{' '} */}
      {/*       <Link to="/billing" className="underline text-neutral-soil"> */}
      {/*         billing page. */}
      {/*       </Link> */}
      {/*     </p> */}
      {/*   </div> */}
      {/*   <Button outline inline onClick={showConfirmDeleteAccount} className="flex-shrink-0"> */}
      {/*     Delete My Account */}
      {/*   </Button> */}
      {/* </DangerZone> */}
    </Container>
  );
}

export default Account;
