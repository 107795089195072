import styled from '@emotion/styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { SubscriptionType, User } from '../../models';
import { UserContextProps } from '../../providers';
import { Button } from '../Button';
import { Card } from '../Card';
import { Input } from '../Input';
import { Loader } from '../Loader';
import { Modal } from '../Modal';

const Container = styled.div<{ inModal: boolean }>`
  ${tw`flex flex-col w-full justify-between space-y-4`}

  max-width: calc(100vw - 3rem);

  ${(p) =>
    !p.inModal &&
    `@media (min-width: 768px) {
    max-width: 21.25rem;
  }`}
`;

const SingleEventOnlyContainer = styled.div<{ inModal: boolean }>`
  ${tw`flex flex-col w-full justify-between`}

  max-width: calc(100vw - 3rem);

  ${(p) =>
    !p.inModal &&
    `@media (min-width: 768px) {
    max-width: 21.25rem;
  }`}
`;

const PasswordContainer = styled.div`
  ${tw`flex flex-col justify-between space-y-4`}
`;

const MembershipOption = styled(Card) <{ selected?: boolean }>`
  ${tw`relative flex flex-col px-4 py-2 overflow-visible border border-neutral-ash space-y-2 cursor-pointer`}

  ${(p) => p.selected && tw`bg-content-mist`}
  
  .selected {
    ${(p) => (p.selected ? tw`visible` : tw`hidden`)}
  }
`;

const InputContainer = styled.div`
  .css-mhducs,
  .css-1ixuyck {
    position: relative;
    margin-bottom: 1rem;
  }
`;

interface UnlockMembershipProps {
  displaySingleOptionOnly?: boolean;
  displaySingleOption?: boolean;
  onSubmit: (option: SubscriptionType) => void;
  userProvider: UserContextProps;
  showShareLinkHintText?: boolean;
  inModal?: boolean;
}

export function UnlockMembership({
  displaySingleOptionOnly = false,
  displaySingleOption = true,
  onSubmit,
  userProvider,
  showShareLinkHintText = true,
  inModal = true
}: UnlockMembershipProps) {
  const [subscriptionType, setSubscriptionType] = useState(SubscriptionType.Year);
  const { user, isPasswordRequiredForUser, update } = userProvider;
  const [shouldAskForEmail, setShouldAskForEmail] = useState(!user?.email);
  const [shouldAskForPassword, setShouldAskForPassword] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const router = useHistory();

  const preSubmitCheck = async (subscriptionType: SubscriptionType) => {
    if (!user) return;
    if (!inModal && subscriptionType === SubscriptionType.Single) {
      router.push('/create/details');
      return;
    }

    if (window.location.pathname === '/account' && subscriptionType === SubscriptionType.Single) {
      router.push('/create/details');
      return;
    }

    setSubscriptionType(subscriptionType);

    const identity = user.phoneNumber ? user.phoneNumber : user.email;
    if (!identity) return;

    setShouldAskForEmail(!user.email);

    const userHasPassword = await isPasswordRequiredForUser(identity);
    setShouldAskForPassword(!userHasPassword);
    if (!userHasPassword || !user.email) {
      openPaymentProtectionModal();
    } else {
      setShouldAskForPassword(false);
      onSubmit(subscriptionType);
    }
  };

  const handleUpdateUser = async (modalData: Partial<User>) => {
    if (!user) return;

    user.email = modalData.email;
    try {
      await update({ ...user, password: modalData.password });
    } catch (e) {
      //@ts-ignore
      const result = await e.json();

      if (result.error.includes('Email')) {
        setError('email', { message: result.error });
      }
      return;
    }

    await preSubmitCheck(subscriptionType);
    closePaymentProtectionModal();
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<Partial<User>>({
    mode: 'onSubmit',
    defaultValues: {
      email: user?.email,
      password: ''
    }
  });

  const action =
    inModal && window.location.pathname !== '/account' ? 'Purchase & Send' : 'Purchase';

  const [openPaymentProtectionModal, closePaymentProtectionModal] = useModal(
    () => (
      <Modal
        onClose={() => {
          if (shouldAskForPassword) {
            setIsRedirecting(false);
          }
          closePaymentProtectionModal();
        }}
      >
        {() => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(handleUpdateUser)();
            }}
          >
            <PasswordContainer>
              {shouldAskForEmail && shouldAskForPassword && (
                <div>
                  We're about to collect payment information from you. Please enter your email and
                  set a password to protect your account.
                </div>
              )}
              {!shouldAskForEmail && shouldAskForPassword && (
                <div>
                  We're about to collect payment information from you. Please set a password to
                  protect your account.
                </div>
              )}
              {shouldAskForEmail && !shouldAskForPassword && (
                <div>
                  We're about to collect payment information from you. Please enter your email.
                </div>
              )}
              {user && (
                <>
                  {shouldAskForEmail && (
                    <InputContainer>
                      <Input
                        value={user.email}
                        placeholder="Email (required)"
                        {...register('email', {
                          required: 'Email is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Must be a valid email address.'
                          }
                        })}
                        autoFocus={!shouldAskForEmail}
                        errors={errors.email}
                      />
                    </InputContainer>
                  )}
                  {shouldAskForPassword && (
                    <InputContainer>
                      <Input
                        value={user.password}
                        type="password"
                        placeholder="Password (required)"
                        {...register('password', {
                          required: 'Password is required'
                        })}
                        autoFocus={!shouldAskForEmail}
                        errors={errors.password}
                      />
                    </InputContainer>
                  )}
                </>
              )}
              <div className="flex flex-col items-center mt-10 space-y-4">
                <Button type="submit">Save</Button>
              </div>
            </PasswordContainer>
          </form>
        )}
      </Modal>
    ),
    [user, shouldAskForEmail, shouldAskForPassword, errors.email, errors.password]
  );

  if (!user) {
    return (
      <Loader>
        <div>Fetching your account...</div>
      </Loader>
    );
  }

  if (isRedirecting) {
    return (
      <Loader>
        <div>Redirecting to Stripe checkout...</div>
      </Loader>
    );
  }

  const isOnAccountPage = window.location.pathname === '/account';

  const handleSelection = async (subscriptionType: SubscriptionType) => {
    if (inModal && !isOnAccountPage) {
      setIsRedirecting(true);
    } else {
      setIsRedirecting(subscriptionType !== SubscriptionType.Single);
    }
    await preSubmitCheck(subscriptionType);
  };

  const singleMembershipButtonLabel = inModal && !isOnAccountPage ? action : 'Try It First';

  const isShowingGetStarted = singleMembershipButtonLabel === 'Try It First' && !isOnAccountPage;

  const singleEventText = `Unlimited invitations, item selection,<br />publicly sharable link, no ads - $9`;

  const singleMembershipOption = (
    <MembershipOption>
      <div className="text-2xl font-semibold text-center">Single Event</div>
      <div
        className="text-sm text-center italic"
        dangerouslySetInnerHTML={{ __html: singleEventText }}
      ></div>

      <Button
        onClick={() => handleSelection(SubscriptionType.Single)}
        inline
        outline={!isShowingGetStarted}
      >
        {singleMembershipButtonLabel}
      </Button>
    </MembershipOption>
  );

  const showReadyToGoTitle = inModal && window.location.pathname !== '/account';

  return (
    <>
      {displaySingleOptionOnly && (
        <SingleEventOnlyContainer className="space-y-0" inModal={inModal}>
          {singleMembershipOption}
        </SingleEventOnlyContainer>
      )}

      {!displaySingleOptionOnly && (
        <Container inModal={inModal}>
          <div className="flex flex-col">
            <div className="text-center justify-between">
              {showReadyToGoTitle && <p className="text-base font-bold">I'm ready to go!</p>}
              {showShareLinkHintText && (
                <p className="text-sm text-neutral-char">
                  Share Link and Event Code available after purchase.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            {displaySingleOption && singleMembershipOption}
            <p className="text-base text-center font-bold">
              Planning a series of events or a full calendar? An ad-free membership is for you!
            </p>
            <MembershipOption>
              <div className="text-2xl font-semibold text-center">Unlimited Season</div>
              <div className="text-sm text-center italic">
                <p>3 months unlimited events - $29</p>
                <p>Perfect for football season or the summer!</p>
              </div>
              <Button onClick={() => handleSelection(SubscriptionType.Month)} inline outline>
                {action}
              </Button>
            </MembershipOption>
            <MembershipOption>
              <div className="text-2xl font-semibold text-center">Unlimited Year</div>
              <div className="text-sm text-center italic">
                <p>12 months unlimited events - $99</p>
                <p>Perfect for organizations and recurring events!</p>
              </div>
              <Button onClick={() => handleSelection(SubscriptionType.Year)} inline outline>
                {action}
              </Button>
            </MembershipOption>
          </div>
        </Container>
      )}
    </>
  );
}

export default UnlockMembership;
